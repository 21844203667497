export default {
  // Artist name
  name: "Marie Pepin",

  // Social links
  links: [
    {
      link: "https://www.instagram.com/_mariepepin_",
      name: "@_mariepepin_",
      icon: "instagram",
    },
    {
      link: "https://twitter.com/_MariePepin",
      name: "@_MariePepin",
      icon: "twitter",
    },
    {
      link: "https://www.tiktok.com/@_mariepepin_",
      name: "@_mariepepin_",
      icon: "tiktok",
    },
    {
      link: "https://opensea.io/MariePepin",
      name: "mariepepin.eth",
      icon: "opensea",
    },
    {
      link: "https://www.paypal.com/paypalme/MTPepin",
      name: "Donation",
    },
  ],
};
